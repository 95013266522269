import React from "react";
import styled from "styled-components";

export const LargeButton = styled.button`
  font-size: 18px;
  padding: 8px 16px;
`;

export const BlockButton = styled.button`
  width: 100%;
`;


type RowColumnTypes = { gap?: number;
};

export const Row = styled.div<RowColumnTypes>`
  display: flex;
  flex-direction: row;
  gap: ${({ gap }) => gap}px;
`;

export const Column = styled.div<RowColumnTypes>`
  display: flex;
  flex-direction: column;
  gap: ${({ gap }) => gap}px;
`;

export const Cell = styled.div``;

export const ExpandableCell = styled(Cell)`
  flex: 1;
`;

export const CollapsibleCell = styled(Cell)`
  flex: 0;
`;

type SeparatorTypes = {
  size?: number;
};

export const Separator = styled.div<SeparatorTypes>`
  width: 100%;
  height: ${({ size }) => size || 10}px;
`;

const SquareEmpty = styled.div`
  width: 20px;
  height: 20px;
  background-color: #ffffff;
  border: solid 1px #000000;
  cursor: pointer;

  &:hover {
    background-color: #f5f6fa;
  }
`;

const SquareWithCheckmark = styled(SquareEmpty)`
  color: #b53471;
  font-size: 20px;
  font-weight: bold;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  :after {
    content: "✓";
  }
`;

type CheckboxProps = {
  checked: boolean;
  label: string;
  onChange: (value: boolean) => void;
  labelStyle?: {
    [key: string]: string | number;
  };
};
export const Checkbox: React.FC<CheckboxProps> = ({
  checked,
  label,
  onChange,
  labelStyle = {},
}) => {
  return (
    <Row gap={10}>
      <CollapsibleCell>
        {checked ? (
          <SquareWithCheckmark onClick={() => onChange(false)} />
        ) : (
          <SquareEmpty onClick={() => onChange(true)} />
        )}
      </CollapsibleCell>
      <ExpandableCell style={labelStyle}>{label}</ExpandableCell>
    </Row>
  );
};
