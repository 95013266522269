import { RadioStationTypes } from "./types";

export const radioStations: RadioStationTypes[] = [
  {
    name: "FLUX FM ChillHop – Chill Beats and LoFi HipHop",
    streamUrl:
      "http://fluxfm.streamabc.net/flx-chillhop-mp3-320-1595440?sABC=61o3oq84%230%232qqpnss01895rqr0s8oq129o03s183o0%23&amsparams=playerid:;skey:1639169412",
    image:
      "https://static.soundresidence.com/radiotal/17a6305c-5410-4e62-b6eb-aa540b3a9a57.jpg",
    homepage: "https://www.fluxfm.de/radio-livestream/",
  },

  {
    homepage: "http://direct.franceinfo.fr/",
    image:
      "https://static.soundresidence.com/radiotal/18e22a29-f23f-45b0-9aab-bbbd47fdf5d8.png",
    name: "france info (lofi)",
    streamUrl: "http://icecast.radiofrance.fr/franceinfo-lofi.mp3",
  },
  {
    homepage: "https://zeno.fm/lofi-world/",
    image:
      "https://static.soundresidence.com/radiotal/2f2aa636-4c53-49a0-a060-90d9d168bb2f.png",
    name: "LoFi World",
    streamUrl:
      "http://node-26.zeno.fm/7w72va6s7k0uv?rj-ttl=5&rj-tok=AAABfaYoL1oA_YN1VnKpA-WVDQ",
  },
  {
    homepage: "https://hunter.fm/radio-lo-fi/",
    image:
      "https://static.soundresidence.com/radiotal/3eec6b69-9128-4358-92fd-c1444c0359b1.jpg",
    name: "Hunter FM - LOFI",
    streamUrl: "https://live.hunter.fm/lofi_high",
  },
  {
    homepage: "https://plaza.one/",
    image:
      "https://static.soundresidence.com/radiotal/56875290-d2b5-45e7-b8f5-3f22ba1354c9.png",
    name: "Nightwave Plaza Opus 64",
    streamUrl: "http://radio.plaza.one/opus_64",
  },
  {
    homepage: "https://0nlineradio.com/",
    image:
      "https://static.soundresidence.com/radiotal/631ff70b-0c42-4078-8cbf-23fa35765411.jpg",
    name: "0nlineradio LO-FI",
    streamUrl: "https://stream.0nlineradio.com/lo-fi?ref=radiobrowser",
  },
  {
    homepage: "https://radcap.ru/lo-fi.html",
    image:
      "https://static.soundresidence.com/radiotal/80030ed9-51a4-436a-84d5-4ef4239e359d.png",
    name: "Radio Caprice - Lo-Fi",
    streamUrl: "http://79.111.14.76:8000/lo-fi",
  },
];
