import React, { useCallback, useEffect, useRef, useState } from "react";
import { Howl, Howler } from "howler";
import "./App.css";
import styled from "styled-components";
import ReactAudioPlayer from "react-audio-player";
import {
  BlockButton,
  Checkbox,
  CollapsibleCell,
  ExpandableCell,
  LargeButton,
  Row,
  Separator,
} from "./components/layout";
import { RadioStationTypes } from "./lib/types";
import { radioStations } from "./lib/constants";

const texture = require("./assets/img/texture.png");

const wallpapers = [
  require("./assets/img/bg1.jpeg"),
  require("./assets/img/bg2.jpeg"),
  require("./assets/img/bg3.jpeg"),
  require("./assets/img/bg4.jpeg"),
  require("./assets/img/bg5.jpeg"),
  require("./assets/img/bg6.jpeg"),
  require("./assets/img/bg7.jpeg"),
  // require("./assets/img/bg8.jpeg"),
  // require("./assets/img/bg9.jpeg"),
  // require("./assets/img/bg10.jpeg"),
  // require("./assets/img/bg11.jpeg"),
  // require("./assets/img/bg12.jpeg"),
  // require("./assets/img/bg13.jpeg"),
];

type WrapperTypes = {
  image: string;
};

const Wrapper = styled.div<WrapperTypes>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #2c3e50;
  background-size: cover;
  background-image: url(${({ image }) => image});
  opacity: ${({ image }) => (image ? 1 : 0)};
  transition: opacity 0.5s ease-in, background 1s linear;
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #00000033;
`;

const Content = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 16px;
`;

type MenuTypes = {
  visible: boolean;
};

const Menu = styled.div<MenuTypes>`
  box-shadow: rgba(0, 0, 0, 0.35) 0 5px 15px;
  background-color: #ffffff;
  position: absolute;
  right: 40px;
  top: ${({ visible }) => (visible ? "40px" : "-500px")};
  padding: 10px;
  border-radius: 10px;
  min-width: 180px;

  transition: top 0.5s ease-in, filter 0.3s ease-in;
  filter: opacity(0.2);

  &:hover {
    filter: opacity(1);
  }
`;

const MenuButton = styled(LargeButton)<MenuTypes>`
  position: absolute;
  right: ${({ visible }) => (visible ? "16px" : "-200px")};
  top: 16px;
  padding: 10px;

  transition: right 0.5s ease-in;
`;

const Credits = styled.div`
  position: absolute;
  bottom: 10px;
  left: 10px;
  color: #ffffff;
  background-color: rgba(0, 0, 0, 0.3);
  font-size: 12px;
  padding: 4px;
  border-radius: 4px;
`;

type RadioListTypes = {
  visible: boolean;
};

const RadioList = styled.div<RadioListTypes>`
  box-shadow: rgba(0, 0, 0, 0.35) 0 5px 15px;
  background-color: #ffffff;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: ${({ visible }) => (visible ? "40px" : "-500px")};
  padding: 10px;
  border-radius: 10px;
  min-width: 180px;
  transition: bottom 0.5s ease-in, filter 0.3s ease-in;
  display: flex;
  flex-direction: row;
  gap: 10px;

  pointer-events: none;
  filter: opacity(0.2);

  &:hover {
    filter: opacity(1);

    div {
      filter: grayscale(1);
    }
  }
`;

type RadioItemTypes = {
  image: string;
};

const RadioItem = styled.div<RadioItemTypes>`
  background-size: cover;
  width: 80px;
  height: 80px;
  background-color: #2c3e50;
  background-image: url(${({ image }) => image});
  transition: transform 0.2s ease-in, filter 0.2s ease-in, box-shadow 0.3s ease-in;
  cursor: pointer;
  pointer-events: auto;
  border-radius: 8px;
  z-index: 1;

  &:hover {
    filter: none !important;
    transform: scale(1.4);
    z-index: 11;
    box-shadow: rgb(85, 91, 255) 0 0 0 3px, #1289A7 0 0 0 6px, #F79F1F 0 0 0 9px, #FFC312 0 0 0 12px, #B53471 0 0 0 15px;
`;

type RadioContainerTypes = {
  visible: boolean;
};

const RadioContainer = styled.div<RadioContainerTypes>`
  box-shadow: rgba(0, 0, 0, 0.35) 0 5px 15px;
  background-color: #ffffff;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: ${({ visible }) => (visible ? "180px" : "-500px")};
  padding: 10px;
  border-radius: 10px;
  min-width: 180px;

  transition: filter 0.3s ease-in, bottom 0.5s ease-in;
  filter: opacity(0.2);

  &:hover {
    filter: opacity(1);
  }
`;

type RadioImageTypes = {
  image: string;
};

const RadioImage = styled.div<RadioImageTypes>`
  background-size: cover;
  width: 140px;
  height: 140px;
  background-color: #ffffff;
  background-image: url(${({ image }) => image});
  border-radius: 8px;
`;

const RadioName = styled.div`
  font-size: 17px;
  font-weight: bold;
  margin: 0 20px;
`;

const howlSounds: { [key: string]: Howl } = {
  rain: new Howl({
    src: [require("./assets/sound/rain-02.mp3")],
    loop: true,
  }),
  thunder: new Howl({
    src: [
      require("./assets/sound/epic-storm-thunder-rainwindwaves-no-loops-106800.mp3"),
    ],
    loop: true,
  }),
  waves: new Howl({
    src: [require("./assets/sound/ocean-waves-1.mp3")],
    loop: true,
  }),

  fire: new Howl({
    src: [require("./assets/sound/fire-1.mp3")],
    loop: true,
  }),
};

type SoundsTypes = {
  rain: boolean;
  thunder: boolean;
  waves: boolean;
  fire: boolean;
};

function App() {
  const [bg, setBg] = useState(texture);
  const [tempBg, setTempBg] = useState<number>(Math.floor(Math.random() * wallpapers.length));
  const [soundVolume, setSoundVolume] = useState<number>(100);
  const [radioVolume, setRadioVolume] = useState<number>(100);

  const [showMenu, setShowMenu] = useState<boolean>(true);
  const [sounds, setSounds] = useState<SoundsTypes>({
    rain: false,
    thunder: false,
    waves: false,
    fire: false,
  });

  const [radioStation, setRadioStation] = useState<RadioStationTypes | null>(
    null
  );
  const [isPlayingRadio, setIsPlayingRadio] = useState<boolean>(false);
  const [playerRef, setPlayerRef] = useState<ReactAudioPlayer | null>(null);

  useEffect(() => {
    setInterval(() => {
      setTempBg((oldValue: number) => (oldValue + 1) % wallpapers.length);
    }, 20000);
  }, []);

  useEffect(() => {
    setIsPlayingRadio(true);
  }, [radioStation]);

  useEffect(() => {
    howlSounds.rain.volume(soundVolume / 100);
    howlSounds.thunder.volume(soundVolume / 100);
    howlSounds.waves.volume(soundVolume / 100);
    howlSounds.fire.volume(soundVolume / 100);
  }, [soundVolume]);

  useEffect(() => {
    if (sounds.rain) {
      howlSounds.rain.play();
    } else {
      howlSounds.rain.stop();
    }

    if (sounds.thunder) {
      howlSounds.thunder.play();
    } else {
      howlSounds.thunder.stop();
    }

    if (sounds.waves) {
      howlSounds.waves.play();
    } else {
      howlSounds.waves.stop();
    }

    if (sounds.fire) {
      howlSounds.fire.play();
    } else {
      howlSounds.fire.stop();
    }
  }, [sounds]);

  const updateSound = useCallback(
    (prop: string, value: boolean) => {
      setSounds({ ...sounds, [prop]: value });
    },

    [sounds]
  );

  return (
    <div className="app">
      <Wrapper image={bg} />
      <img
        style={{ display: "none" }}
        src={wallpapers[tempBg]}
        onLoad={() => {
          setBg(wallpapers[tempBg]);
        }}
      />
      <Overlay />
      <Content>
        <a href="https://about.colorcode.me" target="_blank">
          <LargeButton>about</LargeButton>
        </a>

        {/*
        <a href="https://about.colorcode.me" target="_blank">
          <LargeButton style={{marginLeft: 10}}>buy me a coffee</LargeButton>
        </a>
*/}

        <MenuButton visible={!showMenu} onClick={() => setShowMenu(true)}>
          set the mood &#9834;
        </MenuButton>
        <Menu visible={showMenu}>
          <Separator size={20} />
          <Checkbox
            checked={sounds.rain}
            label={"rain"}
            onChange={(v) => updateSound("rain", v)}
          />
          <Separator />
          <Checkbox
            checked={sounds.thunder}
            label={"thunderstorm"}
            onChange={(v) => updateSound("thunder", v)}
          />
          <Separator />
          <Checkbox
            checked={sounds.waves}
            label={"waves"}
            onChange={(v) => updateSound("waves", v)}
          />
          <Separator />
          <Checkbox
            checked={sounds.fire}
            label={"campfire"}
            onChange={(v) => updateSound("fire", v)}
          />
          <Separator size={20} />
          <input
            style={{ width: "100%" }}
            type="range"
            name="volume"
            value={soundVolume}
            min="0"
            max="100"
            onChange={(e) => setSoundVolume(parseInt(e.target.value))}
          />
          <Separator size={20} />

          <BlockButton onClick={() => setShowMenu(false)}>
            hide menus
          </BlockButton>
        </Menu>

        {radioStation && (
          <RadioContainer visible={showMenu}>
            <Row>
              <CollapsibleCell>
                <RadioImage image={radioStation.image} />
              </CollapsibleCell>
              <ExpandableCell
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "0 20px",
                }}
              >
                <RadioName>{radioStation.name}</RadioName>
                <ReactAudioPlayer
                  src={radioStation.streamUrl}
                  autoPlay
                  ref={(element) => setPlayerRef(element)}
                  volume={radioVolume / 100}
                />
                <Separator />
                {isPlayingRadio ? (
                  <button
                    onClick={() => {
                      setIsPlayingRadio(false);
                      // @ts-ignore
                      playerRef?.audioEl.current?.pause();
                    }}
                  >
                    pause
                  </button>
                ) : (
                  <button
                    onClick={() => {
                      setIsPlayingRadio(true);
                      // @ts-ignore
                      playerRef?.audioEl.current.play();
                    }}
                  >
                    play
                  </button>
                )}
                <Separator size={20} />
                <input
                  style={{ width: "100%" }}
                  type="range"
                  name="volume"
                  value={radioVolume}
                  min="0"
                  max="100"
                  onChange={(e) => setRadioVolume(parseInt(e.target.value))}
                />
              </ExpandableCell>
            </Row>
          </RadioContainer>
        )}

        <RadioList visible={showMenu}>
          {radioStations.map((station: RadioStationTypes) => {
            return (
              <RadioItem
                image={station.image}
                key={station.name}
                onClick={() => setRadioStation(station)}
              />
            );
          })}
        </RadioList>
      </Content>

      <Credits>Wallpaper by https://hdqwalls.com/</Credits>
    </div>
  );
}

export default App;
